import moment from 'moment'
import {
	UI_VIEW_CALL_RECORDS_FROM,
	UI_VIEW_DELIVERIES_FROM,
	UI_VIEW_STORE_DELIVERY_ADDRESS_DISTANCE,
	UI_VISIBLE_COMPONENTS,
	UI_AGENT_TALKING_WITH_CUSTOMER,
	UI_AGENT_HANDLING_CALL,
	UI_SELECT_ORDER_LOCATION,
	UI_OPEN_MODAL_CUSTOMER_DATA_LOAD,
	UI_CLOSE_MODAL_CUSTOMER_DATA_LOAD,
	UI_LOAD_CUSTOMER_ADDRESS_IDX,
	UI_LOADED_CUSTOMER_ADDRESS_COUNT,
	UI_RESET_CUSTOMER,
	UI_SELECT_PAYMENT_TYPE,
	UI_HANDOFF_LOCATION,
	UI_TOGGLE_COMBO,
	UI_TOGGLE_GRATIS,
	UI_STORE_ORDER_DELAY_TIME,
	UI_VIEW_CLEAR_DELIVERY_ADDRESS_DISTANCE,
	UI_SELECT_DELIVERY_TYPE,
	UI_SELECT_DISCOUNT_VALUE
} from '../../actions/UI'
import { ACTIVATE_AGENT_LISTENER } from '../../actions/agent'

const initialState = {
	callRecordsDate: moment().startOf('date'),
	deliveriesDate: moment().startOf('date'),
	uiVisibleComponents: {
		ui_delay: false,
		ui_gratis: true,
		ui_nth_gratis: false,
		ui_loyalty: false,
		ui_mini_stats: false,
		ui_distance: false,
		ui_discount: true,
		ui_payment_opts: false,
		ui_map_overlay: false,
		ui_call_stats: false,
		ui_tablet_view: false,
		ui_dialed_number_filter: false,
	},
	activeAgent: null,
	loadedCustomer: null,
	loadedCall: null,
	selectedLocation: null,
	selectedDeliveryType: null,
	selectedPaymentType: null,
	selectedDiscountValue: null,
	loadedCustomerAddressIndex: 1,
	loadedCustomerAddressCount: null,
	modalCustomerDataLoad: false,
	comboEnabled: true,
	gratisEnabled: false,
	deliveryAddressDistance: null,
	storeOrderDelayTime: null,
}


const callRecordsDateReducer = (state = initialState.callRecordsDate, action) => {
	switch (action.type) {
		case UI_VIEW_CALL_RECORDS_FROM:
			return action.payload
			break
		default:
			return state
	}
}

const deliveriesDateReducer = (state = initialState.deliveriesDate, action) => {
	switch (action.type) {
		case UI_VIEW_DELIVERIES_FROM:
			return action.payload
			break
		default:
			return state
	}
}

const deliveryAddressDistanceReducer = (state = initialState.deliveryAddressDistance, action) => {
	switch (action.type) {
		case UI_VIEW_STORE_DELIVERY_ADDRESS_DISTANCE:
			return action.payload
		case UI_VIEW_CLEAR_DELIVERY_ADDRESS_DISTANCE:
			return initialState.deliveryAddressDistance;
		case UI_RESET_CUSTOMER:
			return initialState.deliveryAddressDistance;
		default:
			return state
	}
}

const uiVisibleComponentsReducer = (state = initialState.uiVisibleComponents, action) => {
	switch (action.type) {
		case UI_VISIBLE_COMPONENTS:
			return action.payload
			break
		default:
			return state
	}
}

const uiActiveAgentReducer = (state = initialState.activeAgent, action) => {
	switch (action.type) {
		case ACTIVATE_AGENT_LISTENER:
			return action.payload
			break
		default:
			return state
	}
}

const uiLoadedCustomer = (state = initialState.loadedCustomer, action) => {
	switch (action.type) {
		case UI_AGENT_TALKING_WITH_CUSTOMER:
			return action.payload
			break
		case UI_RESET_CUSTOMER:
			return initialState.loadedCustomer
			break
		default:
			return state
	}
}

const uiLoadedCall = (state = initialState.loadedCall, action) => {
	switch (action.type) {
		case UI_AGENT_HANDLING_CALL:
			return action.payload
			break
		case UI_RESET_CUSTOMER:
			return initialState.loadedCall
			break
		default:
			return state
	}
}

const uiSelectedLocation = (state = initialState.selectedLocation, action) => {
	switch (action.type) {
		case UI_SELECT_ORDER_LOCATION:
			return action.payload
			break
		case UI_RESET_CUSTOMER:
			return initialState.selectedLocation
			break
		default:
			return state
	}
}

const uiSelectedDeliveryType = (state = initialState.selectedDeliveryType, action) => {
	switch (action.type) {
		case UI_SELECT_DELIVERY_TYPE:
			return action.payload
			break
		case UI_RESET_CUSTOMER:
			return initialState.selectedDeliveryType
			break
		default:
			return state
	}
}

const uiSelectedPaymentType = (state = initialState.selectedPaymentType, action) => {
	switch (action.type) {
		case UI_SELECT_PAYMENT_TYPE:
			return action.payload
			break
		case UI_RESET_CUSTOMER:
			return initialState.selectedPaymentType
			break
		default:
			return state
	}
}

const uiSelectedDiscountValue = (state = initialState.selectedDiscountValue, action) => {
	switch (action.type) {
		case UI_SELECT_DISCOUNT_VALUE:
			return action.payload
			break
		case UI_RESET_CUSTOMER:
			return initialState.selectedDiscountValue
			break
		default:
			return state
	}
}


const uiLoadedCustomerAddressIndex = (state = initialState.loadedCustomerAddressIndex, action) => {
	switch (action.type) {
		case UI_LOAD_CUSTOMER_ADDRESS_IDX:
			return action.payload
			break
		case UI_RESET_CUSTOMER:
			return initialState.loadedCustomerAddressIndex
			break
		default:
			return state
	}
}


const uiLoadedCustomerAddressCount = (state = initialState.loadedCustomerAddressCount, action) => {
	switch (action.type) {
		case UI_LOADED_CUSTOMER_ADDRESS_COUNT:
			return action.payload
			break
		case UI_RESET_CUSTOMER:
			return initialState.loadedCustomerAddressCount
			break
		default:
			return state
	}
}


const uiModalCustomerDataLoad = (state = initialState.modalCustomerDataLoad, action) => {
	switch (action.type) {
		case UI_OPEN_MODAL_CUSTOMER_DATA_LOAD:
			return true
			break
		case UI_CLOSE_MODAL_CUSTOMER_DATA_LOAD:
			return false
			break
		default:
			return state
	}
}

const uiHandoffLocation = (state, action) => {
	switch (action.type) {
		case UI_HANDOFF_LOCATION:
			return action.payload
			break
		default:
			return state
	}

}

const comboToggler = (state, action) => {
	switch (action.type) {
		case UI_TOGGLE_COMBO:
			if (state) {
				return false
			} else {
				return true
			}
			break
		case UI_RESET_CUSTOMER:
			return initialState.comboEnabled;
			break
		default:
			return state;
	}
}

const gratisToggler = (state, action) => {
	switch (action.type) {
		case UI_TOGGLE_GRATIS:
			if (state) {
				return false
			} else {
				return true
			}
			break
		case UI_RESET_CUSTOMER:
			return initialState.gratisEnabled;
			break
		default:
			return state;
	}
}

const uiStoreOrderDelayTime = (state = initialState.storeOrderDelayTime, action) => {
	switch (action.type) {
		case UI_STORE_ORDER_DELAY_TIME:
			return action.payload
		case UI_RESET_CUSTOMER:
			return initialState.storeOrderDelayTime
		default:
			return state
	}
}


const viewUI = (state = initialState, action) => {
	switch (action.type) {
		default:
			return {
				callRecordsDate: callRecordsDateReducer(state.callRecordsDate, action),
				deliveriesDate: deliveriesDateReducer(state.deliveriesDate, action),
				deliveryAddressDistance: deliveryAddressDistanceReducer(state.deliveryAddressDistance, action),
				uiVisibleComponents: uiVisibleComponentsReducer(state.uiVisibleComponents, action),
				activeAgent: uiActiveAgentReducer(state.activeAgent, action),
				loadedCustomer: uiLoadedCustomer(state.loadedCustomer, action),
				loadedCall: uiLoadedCall(state.loadedCall, action),
				selectedLocation: uiSelectedLocation(state.selectedLocation, action),
				selectedDeliveryType: uiSelectedDeliveryType(state.selectedDeliveryType, action),
				selectedPaymentType: uiSelectedPaymentType(state.selectedPaymentType, action),
				selectedDiscountValue: uiSelectedDiscountValue(state.selectedDiscountValue, action),
				loadedCustomerAddressIndex: uiLoadedCustomerAddressIndex(state.loadedCustomerAddressIndex, action),
				loadedCustomerAddressCount: uiLoadedCustomerAddressCount(state.loadedCustomerAddressCount, action),
				modalCustomerDataLoad: uiModalCustomerDataLoad(state.modalCustomerDataLoad, action),
				handoffLocation: uiHandoffLocation(state.handoffLocation, action),
				comboEnabled: comboToggler(state.comboEnabled, action),
				gratisEnabled: gratisToggler(state.gratisEnabled, action),
				orderDelayTime: uiStoreOrderDelayTime(state.orderDelayTime, action),
			}
	}
}

export default viewUI;
