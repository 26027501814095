import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

import { viewUIcallRecordsFrom,  } from '../../actions/UI'

// selectors
import { getCallRecordsForDate, getUIviewCallDate } from '../../selectors/calls'
import { getAgentMap } from '../../selectors/agent';

// UI
import CallRecordsTable from '../../components/UI/CallRecordsTable'
import DatePickerUI from '../../components/UI/DatePickerUI'
import { getDeliveries } from '../../selectors/delivery';


const mapStateToProps = (state, props) => {
    return {
        callDate: getUIviewCallDate(state),
        calls: getCallRecordsForDate(state, props),
        agentsMap: getAgentMap(state),
        deliveriesMap: getDeliveries(state),
        uiDialedNumberFilter: state.viewUI.uiVisibleComponents.ui_dialed_number_filter,
    }
}

class CallList extends Component {
    state = {
        selectedDialedNumber: undefined,
        filteredCalls: [],
        callsDialedNumbers: []
    };

    componentDidMount() {
		this.setState({
			filteredCalls: this.filterCalls(),
            callsDialedNumbers: this.getCallsDialedNumbers()
		});
	}

    componentDidUpdate(prevProps, prevState) {
		// on new deliveries
		if (this.props !== prevProps && this.props.calls !== prevProps.calls) {
			this.setState({
                filteredCalls: this.filterCalls(),
                callsDialedNumbers: this.getCallsDialedNumbers(),
				selectedDialedNumber: undefined,
			});
		}

		// on filter change
		if (this.state.selectedDialedNumber !== prevState.selectedDialedNumber) {
			this.setState({
				filteredCalls: this.filterCalls(),
			});
		}
    }

    render() {
        const { callDate, agentsMap, deliveriesMap, uiDialedNumberFilter } = this.props

        return (
            <div>
                <div className="hcf-containers__commandContainer">
                    <Form inline onSubmit={(e) => e.preventDefault()}>
                        {/* ----- date filter ----- */}
                        <FormGroup>
                            <ControlLabel>Pozivi dana:&nbsp;</ControlLabel>
                            <FormControl componentClass={DatePickerUI} value={callDate} onChange={this.handleFormChange} />
                        </FormGroup>

                        {uiDialedNumberFilter && (
                            <React.Fragment>
                                &nbsp;
                                {/* ----- dialed number filter ----- */}
                                <FormGroup>
                                    <ControlLabel>Pozivi na br.:&nbsp;</ControlLabel>
                                    <FormControl componentClass="select" value={this.state.selectedDialedNumber} onChange={this.handleDialedNumberSelect}>
                                        <option value="">svi pozivani brojevi</option>
                                        {this.state.callsDialedNumbers.map((dialedNum) => (
                                            <option key={dialedNum}>{dialedNum}</option>
                                        ))}
                                    </FormControl>
                                </FormGroup>
                            </React.Fragment>
                        )}
                    </Form>
                </div>

                <div>&nbsp;</div>

                <CallRecordsTable calls={this.state.filteredCalls} agents={agentsMap} deliveries={deliveriesMap} />
            </div>
        );
    }

    handleFormChange = (selectedDate) => {
        const { viewUIcallRecordsFrom,  } = this.props

        viewUIcallRecordsFrom(selectedDate);
        (selectedDate);
    }

	handleDialedNumberSelect = (event) => {
		const value = event.target.value;
		this.setState({
			selectedDialedNumber: value != '' ? value : undefined
		});
	}

   	/**
	 * Filter calls list by seleced filters.
	 * Date filter used with store selector but dialed number filters is applied locally on calls list in component props.
	 */
	filterCalls = () => {
		return (this.props.calls || [])
			// filter by dialed number
			.filter((call) => {
				return this.state.selectedDialedNumber == null || call.dialed_number != null && this.state.selectedDialedNumber === call.dialed_number;
			});
	}

	/** Get unique list of dialed numbers from list of calls. */
	getCallsDialedNumbers = () => {
		return _.uniq(
			// convert to list of non-empty dialed numbers
			(this.props.calls || []).filter((call) => call.dialed_number != null).map(call => call.dialed_number)
		);
	}
}

export default connect(
    mapStateToProps, {
    viewUIcallRecordsFrom,
    
}
)(CallList);